/* eslint-disable react/prop-types */

import React, { useContext, useState } from "react";
import tw, { css, theme } from "twin.macro";
import useSanityShopifyProducts from "~hooks/useSanityShopifyProducts.jsx";
import ScreenHeight from "~components/ScreenHeight.jsx";
import { AppContext } from "~context/AppContext.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as A from "~components/styles/Animations.jsx";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import Button from "~components/Button.jsx";
import Image from "~components/Image.jsx";
import Go from "~components/Go.jsx";
import { useTimeout } from "~utils/hooks";

const Nav = () => {
  // ===========================================================================
  // context / ref / state

  const {
    cart,
    cartActive,
    setCartActive,
    checkout,
    decreaseQuantityByCartIndex,
    increaseQuantityByCartIndex,
    menuActive,
    setMenuActive
  } = useContext(AppContext);

  const { isDesktop, screen, scrollTop } = useContext(DocumentContext);

  const { allSanityShopifyProduct } = useSanityShopifyProducts();

  const [rendered, setRendered] = useState(false);

  useTimeout(() => {
    setRendered(true);
  }, 1000);

  // ===========================================================================
  // variables

  const cartList = [];

  let cartTotal = 0;

  if (cart?.[0] && allSanityShopifyProduct?.edges?.[0]) {
    cart.forEach((cartItem) => {
      allSanityShopifyProduct.edges.forEach(({ node }) => {
        const sanityProduct = node;

        if (!sanityProduct?.variants?.[0]) {
          return;
        }

        sanityProduct.variants.forEach((variant) => {
          if (cartItem.variantId === variant.id) {
            const price = parseFloat(variant.sourceData.priceV2.amount).toFixed(
              2
            );
            const lineItemPrice = parseFloat(price * cartItem.quantity);

            cartTotal += lineItemPrice;

            let image;

            if (variant?.image?.asset) {
              image = variant.image;
            } else if (sanityProduct?.image?.asset) {
              image = sanityProduct.image;
            } else if (
              sanityProduct?.shopifyProduct?.sourceData?.images?.[0]
                ?.originalSrc
            ) {
              image =
                sanityProduct.shopifyProduct.sourceData.images[0].originalSrc;
            }

            //

            cartList.push({
              data: sanityProduct,
              quantity: cartItem.quantity,
              image,
              price,
              selectedOptions: variant?.sourceData?.selectedOptions,
              lineItemPrice
            });
          }
        });
      });
    });
  }

  // ===========================================================================
  // lifecycle

  // useEffect(() => {
  //   if (cartActive) {
  //     setCartActive(false);
  //   }
  // }, [scrollTop]);

  // ===========================================================================
  // render

  return (
    <ScreenHeight
      inject={{ tw: tw`w-screen fixed z-20 md:z-50 pointer-events-none` }}
    >
      {screen && !isDesktop() && (
        <div
          css={[
            css`
              transition: transform 0.5s ${A.EASING_CUBIC};
              transform: translate3d(${menuActive ? 0 : -100}%, 0, 0);
              pointer-events: ${menuActive ? `auto` : `none`};
            `,
            tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-20 flex items-center justify-center bg-black text-left text-white`
          ]}
        >
          <div css={[tw`w-full relative`]}>
            <Grid>
              <li css={[tw`col-span-12 relative block`]}>
                <Go to="/" onClick={() => setMenuActive(false)}>
                  <T.Heading font="2" level="3" inject={{ tw: tw`py-2` }}>
                    Home
                  </T.Heading>
                </Go>
              </li>

              <li css={[tw`col-span-12 relative block`]}>
                <Go to="/products" onClick={() => setMenuActive(false)}>
                  <T.Heading font="2" level="3" inject={{ tw: tw`py-2` }}>
                    Shop
                  </T.Heading>
                </Go>
              </li>

              <li css={[tw`col-span-12 relative block`]}>
                <Go to="/about" onClick={() => setMenuActive(false)}>
                  <T.Heading font="2" level="3" inject={{ tw: tw`py-2` }}>
                    About
                  </T.Heading>
                </Go>
              </li>

              <li css={[tw`col-span-12 relative block`]}>
                <Go to="/features" onClick={() => setMenuActive(false)}>
                  <T.Heading font="2" level="3" inject={{ tw: tw`py-2` }}>
                    Features
                  </T.Heading>
                </Go>
              </li>

              <li css={[tw`col-span-12 relative block mt-16`]}>
                <Go to="/contact" onClick={() => setMenuActive(false)}>
                  <T.Heading font="2" level="3" inject={{ tw: tw`py-2` }}>
                    Contact
                  </T.Heading>
                </Go>
              </li>
            </Grid>
          </div>
        </div>
      )}

      {rendered && (
        <div css={[tw`w-full h-full relative flex items-end justify-end`]}>
          <div
            css={[
              css`
                transition: transform 0.3s ${A.EASING_CUBIC};

                ${isDesktop()
                  ? `
                transform: translate3d(${cartActive ? 0 : `525px`}, 0, 0);
                width: 525px;
              `
                  : `
                transform: translate3d(${cartActive ? 0 : `100vw`}, 0, 0);
                width: 100vw;
              `}
              `,
              tw`h-full relative flex flex-col pointer-events-auto pt-16 md:pt-8 pr-3 md:pr-12 md:pb-8 pl-3 md:pl-12 bg-green`
            ]}
          >
            <header
              css={[
                tw`w-full relative flex items-center justify-between pt-8 md:pt-0 mb-6 md:mb-12`
              ]}
            >
              <T.Heading font={isDesktop() ? `2` : `3`} level="2">
                Your Cart
              </T.Heading>

              {isDesktop() && (
                <button
                  type="button"
                  css={[tw`relative block -mr-4`]}
                  onClick={() => setCartActive(false)}
                >
                  <div
                    css={[
                      tw`w-16 h-16 relative flex items-center justify-center`
                    ]}
                  >
                    <Icon.Cross css={[tw`w-full h-full`]} />
                  </div>
                </button>
              )}
            </header>

            {(cartList?.[0] && (
              <>
                <ul
                  css={[
                    css`
                      flex: 1 1 auto;
                    `,
                    tw`w-full h-full relative block pb-8 overflow-y-scroll`
                  ]}
                >
                  {cartList.map((cartItem, cartItemIndex) => {
                    const cartItemKey = `cart-${cartItemIndex}`;
                    const { data } = cartItem;

                    //

                    return (
                      <li
                        key={cartItemKey}
                        css={[tw`w-full relative flex md:pt-8 pb-12 md:pb-8`]}
                      >
                        <figure css={[tw`w-1/4 mt-1 pr-4`]}>
                          {cartItem?.image && <Image image={cartItem.image} />}
                        </figure>

                        <div css={[tw`w-3/4 pl-2`]}>
                          <T.Body
                            inject={{
                              css: `font-weight: 900;`,
                              tw: tw`mb-1 md:mb-2 uppercase`
                            }}
                          >
                            {data.title}
                          </T.Body>

                          <ul>
                            <li css={[tw`w-full flex`]}>
                              <T.Body
                                inject={{
                                  css: `font-weight: 900;`,
                                  tw: tw`w-1/2 mt-1 md:mb-1`
                                }}
                                font={isDesktop() ? `1` : `2`}
                              >
                                Price
                              </T.Body>
                              <T.Body
                                inject={{ tw: tw`w-1/2 mt-1 md:mb-1` }}
                                font={isDesktop() ? `1` : `2`}
                              >
                                ${cartItem.price}
                              </T.Body>
                            </li>

                            {cartItem?.selectedOptions?.[0] &&
                              cartItem.selectedOptions.map((selectedOption) => {
                                const key = `${cartItem.handle}-${selectedOption.name}`;

                                let { name } = selectedOption;

                                switch (name?.toLowerCase()) {
                                  case `color`:
                                  case `colour`:
                                    name = `Fabric`;
                                    break;

                                  case `size`:
                                    name = `Configuration`;
                                    break;

                                  default:
                                    break;
                                }

                                return (
                                  <li
                                    css={[
                                      tw`w-full relative flex items-center justify-between`
                                    ]}
                                    key={key}
                                  >
                                    <T.Body
                                      inject={{
                                        css: `font-weight: 900;`,
                                        tw: tw`w-1/2 mt-1 md:mb-1`
                                      }}
                                      font={isDesktop() ? `1` : `2`}
                                    >
                                      {name}
                                    </T.Body>
                                    <T.Body
                                      inject={{ tw: tw`w-1/2 mt-1 md:mb-1` }}
                                      font={isDesktop() ? `1` : `2`}
                                    >
                                      {selectedOption.value}
                                    </T.Body>
                                  </li>
                                );
                              })}
                          </ul>

                          <div tw="w-full relative flex items-center mt-8">
                            <div tw="w-1/3 h-10 flex items-center">
                              <T.Body>{cartItem.quantity}</T.Body>
                            </div>

                            <div tw="relative flex items-center">
                              <button
                                type="button"
                                css={[
                                  css`
                                    border-radius: 50%;
                                    border: 1px solid ${theme`colors.black`};

                                    @media not all and (pointer: coarse) {
                                      &:hover {
                                        background: ${theme`colors.black`};
                                        color: ${theme`colors.white`};
                                      }
                                    }
                                  `,
                                  tw`w-8 md:w-10 h-8 md:h-10 block mr-2 overflow-hidden`
                                ]}
                                onClick={() =>
                                  decreaseQuantityByCartIndex(cartItemIndex)
                                }
                              >
                                <T.Body
                                  inject={{
                                    tw: css`
                                      margin-top: -0.25rem;
                                    `
                                  }}
                                >
                                  -
                                </T.Body>
                              </button>

                              <button
                                type="button"
                                css={[
                                  css`
                                    border-radius: 50%;
                                    border: 1px solid ${theme`colors.black`};

                                    @media not all and (pointer: coarse) {
                                      &:hover {
                                        background: ${theme`colors.black`};
                                        color: ${theme`colors.white`};
                                      }
                                    }
                                  `,
                                  tw`w-8 md:w-10 h-8 md:h-10 block overflow-hidden`
                                ]}
                                onClick={() =>
                                  increaseQuantityByCartIndex(cartItemIndex)
                                }
                              >
                                <T.Body>+</T.Body>
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>

                <div css={[tw`w-full relative pb-4 border-t`]}>
                  <T.Body
                    inject={{
                      css: `font-weight: 900;`,
                      tw: tw`mt-8 md:mt-16 mb-8 md:mb-16`
                    }}
                  >
                    <span css={[tw`mr-6`]}>Total</span>
                    <span css={[tw`mr-6`]}>
                      ${parseFloat(cartTotal).toFixed(2)}
                    </span>
                  </T.Body>

                  <Button
                    color="green"
                    onClick={() => checkout(allSanityShopifyProduct)}
                    inject={{ tw: tw`w-64 h-10` }}
                    text="Proceed to checkout"
                  />
                </div>
              </>
            )) || (
              <div
                css={[
                  css`
                    ${A.Keyframes(
                      `appearDown`,
                      `0.5s ${A.EASING_CUBIC} forwards`,
                      `0.25s`
                    )}
                  `,
                  tw`w-full relative text-center`
                ]}
              >
                <T.Body>Your cart is empty.</T.Body>

                <Go to="/products">
                  <Button
                    color="black"
                    font="l"
                    onClick={() => setCartActive(false)}
                    inject={{ tw: tw`w-full mt-8 h-12` }}
                    text="Continue shopping"
                  />
                </Go>
              </div>
            )}
          </div>
        </div>
      )}
    </ScreenHeight>
  );
};

export default Nav;
